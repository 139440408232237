import React from 'react'
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from 'react-router-dom';
import { getCircular } from '../Service/Api';
const AnnualReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedClass, setSelectedClass] = useState('9'); // Default to Class 9

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for DD/MM/YYYY format
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCircular();
      console.log('Fetched data:', response); // Debug: Check the structure of the fetched data
      setData(response);
    };
    fetchData();
  }, []);

  // Handle change for class selection
  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      console.log('Selected Class:', selectedClass); // Debug: Log the selected class
      console.log('Current Data:', data); // Debug: Log the current data

      // Adjust the filtering logic to match class with the select dropdown values
      const filtered = data.filter(
        (item) => item.category === 'AnnualReport'  // Ensure 'class' is correctly parsed
      );

      console.log('Filtered Data:', filtered); // Debug: Log the filtered data
      setFilteredData(filtered);
    };
    filterData();
  }, [selectedClass, data]); // Run this effect whenever selectedClass or data changes

  const renderTable = () => (
    <div className="col-lg-12">
      <div className="table-responsive maintable"><table className="table"><thead>
        <tr>
          <th width="10%" className="text-center">S.NO </th><th width="60%" className="text-center"> DOCUMENTS/INFORMATION	</th>
          <th width="30%" className="text-center">Download/View </th></tr>
      </thead>

        <tbody>
          {filteredData.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td><Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">View</Link></td>

            </tr>
          ))}
        </tbody>
      </table>
      </div>

    </div>
  );
  return (
    <>
      <Header />
      <Breadcrumb mid="Annual Report" />
      <div className="innerSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title">
                <div>
                  <h3><span>Annual Report</span> </h3>
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            {/* <div className="col-lg-12">
              <div className="table-responsive maintable"><table className="table"><thead>
                <tr>
                  <th width="10%" className="text-center">S.NO </th><th width="60%" className="text-center">DOCUMENTS/INFORMATION </th><th width="30%" className="text-center">UPLOAD DOCUMENTS</th></tr>
              </thead><tbody>
                  <tr>
                    <td>1 </td>
                    <td> Annual Report Quest 22-23 (1 Edition)   </td>
                    <td><Link to="/pdf/AnnualReportQuest22-23.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                    <td>2 </td>
                    <td> Annual Report Quest 22-23  (2 Edition) </td>
                    <td><Link to="/pdf/AnnualReportQuest22-23(2).pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                    <td>3 </td>
                    <td> Annual Report Quest 19-20 (1 Edition)   </td>
                    <td><Link to="/pdf/AnnualReportQuest19-20.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                    <td>4 </td>
                    <td> Annual Report Quest 19-20  (2 Edition) </td>
                    <td><Link to="/pdf/AnnualReportQuest19-20(2).pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                    <td>5 </td>
                    <td> Annual Report Quest 18-19 (1 Edition)   </td>
                    <td><Link to="/pdf/AnnualReportQuest18-19.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>

                  <tr>
                    <td>6 </td>
                    <td> Annual Report Quest 18-19  (2 Edition) </td>
                    <td><Link to="/pdf/AnnualReportQuest18-19(2).pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                    <td>7 </td>
                    <td> Annual Report Quest  2018   </td>
                    <td><Link to="/pdf/AnnualReportQuest18.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td> Annual Report Quest 2016  </td>
                    <td><Link to="/pdf/AnnualReportQuest16.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</Link></td>
                  </tr>
                </tbody>
              </table>
              </div>

            </div> */}
          </div>
          {renderTable()}
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export default AnnualReport
