import { useEffect, useState } from "react";
import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getCircular } from "../Service/Api";  
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Circulars = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All"); 
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [monthOptions, setMonthOptions] = useState(["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
        console.log("get current Year", Year);
     }, []);

    useEffect(() => {
        const fetchData = async () => {
            const circularData = await getCircular();
            console.log("circular data>>>",circularData); 
            setData(circularData);
        };
        fetchData();
    }, []);
  
    useEffect(() => {
        if(!data) {
            return;
        }
        console.log(selectedMonth, selectedYear, searchQuery);
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredData(filteredData);
        const datas = filteredData.filter((div) => div.category === "Circular");
        setFilteredData(datas);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    useEffect(() => {
        console.log('filterData', filterData)
    }, [filterData]);

  

    const emptyArray = [
        {title:"Circular Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"},
        {title:"Circular Title",description:"Stay Tunned For More Updates",day:"DD",month:"MM"}
       ];
    return (
        <>
        
        <Header />
        <Breadcrumb mid="Circulars"/>
        <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="title">
                            <div>
                                <h3><span>Circulars</span> </h3>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                    {filterData.length > 0 ? (
                            filterData.map((item, index) =>  {
                    
                            const date = new Date(item.date);
                            const day = date.getDate();
                            const month = monthNames[date.getMonth()];
                            return (
                                <div className="col-lg-12 col-xl-6 newsCount" key={index}>
                                    <div className="news-blk">
                                        <div className="news-head">
                                            <h2> {day} <span> {month} </span> </h2>
                                            <h3>{item.title}</h3>
                                        </div>
                                        <div className="news-content">
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="attachment">
                                            <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                );
                            } 
                          )) : (
                            emptyArray.map((item, index) => (
                                <div className="col-lg-12 col-xl-6 newsCount" key={index}>
                                <div className="news-blk">
                                    <div className="news-head">
                                        <h2>{item.day}  <span>{item.month}</span>  </h2>
                                        <h3> {item.title}</h3>
                                    </div>
                                    <div className="news-content">
                                        <p>{item.description}</p>
                                    </div>
                                    <div className="attachment">
                                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                    </div>
                                </div>
                            </div>
                            ))
                       )}
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Circulars;
