import React, { useEffect, useState } from "react"; 
import { Link } from "react-router-dom";
import { getNews } from "../Service/Api";

const News = () => {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getNews();
          setNewsData(data);
        } catch (error) {
          console.error("Failed to fetch news:", error);
          setNewsData([]); // Set to empty to ensure the empty state is handled
        }
      };
      fetchData();
    }, []);
    const emptyArray = [
        { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
        { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
        { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" }
    ];
    return (
        <>
            <marquee scrollamount="2" scrolldelay="30" direction="up" align="left" onmouseover="stop();" onmouseout="start();">
            {newsData.length > 0 ?  newsData?.filter((item) =>  item.category === "News").map((item, index) => (   
                <div className='newsarea' key={index}>
                                <div>
                                <p><b>{item.title}</b></p>
                                <p className='newshead'>{item.description}</p>
                                </div>
                                     {item.attachments && item.attachments[0] && (  
                                        <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">
                                        <div class="button padding arrow"> <div class="img mar-0"> <img src="https://webapi.entab.info/api/image/TPSP/public/Images/newsattachment.png" /></div></div>
                                        </Link>
                                        )}  
                                
                            </div>
    
                  )) : 
              emptyArray.map((data, index) => (
                 <div className='newsarea' key={index}>
                                <div>
                                <p><b>{data.title}</b></p>
                                <p className='newshead'>{data.description}</p>
                                </div>
                                   
                                        <Link to="#" target="_blank">
                                        <div class="button padding arrow"> <div class="img mar-0"> <img src="https://webapi.entab.info/api/image/TPSP/public/Images/newsattachment.png" /></div></div>
                                        </Link> 
                            </div>
                  ))
                }
            </marquee>
        </>
    );
};

export default News;
