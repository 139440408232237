import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const ContactBranch = () => {
    var settings4 = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,       
        controls: true,
        nav: true,
        dots: false,
        items: 1
    };
    return(
        <> 
            <OwlCarousel className='owl-theme' loop margin={10} {...settings4}>
                <div className="item">
                    <div className='branch-blk'>
                        <h4>OUR BRANCHES</h4>
                        <p><b>Ballabgarh Campus</b></p>
                        <p><i class="bi bi-geo-alt"></i>
                        Tagore Academy, Sector 3, Ballabgarh, Faridabad Haryana
                        </p>
                        <p><i class="bi bi-telephone-fill"></i> +91 (0129) 2241521 , 2300548, 2211217</p>
                        <p><i class="bi bi-envelope"></i> <div><a href="mailto:tagore_academy@rediffmail.com">tagore_academy@rediffmail.com</a>   <a href="mailto:tagorepalwal@gmail.com ">tagorepalwal@gmail.com </a></div></p>
                        <div class="button light padding">&nbsp;<span>Locate Us </span><img src="https://webapi.entab.info/api/image/TPSP/public/Images/arrow.png" /> </div>
                    </div>
                </div>
                <div className="item">
                    <div className='branch-blk'>
                        <h4>OUR BRANCHES</h4>
                        <p><b>Faridabad Campus</b></p>
                        <p><i class="bi bi-geo-alt"></i>
                        Block G, Sector 89, Faridabad
                        </p>
                        <p><i class="bi bi-telephone-fill"></i> +91-8527733139</p>
                        <p><i class="bi bi-envelope"></i>  <div><a href="mailto:tagore_academy@rediffmail.com">tagore_academy@rediffmail.com</a>  <a href="mailto:tagorepalwal@gmail.com ">tagorepalwal@gmail.com </a></div></p>
                        <div class="button light padding">&nbsp;<span>Locate Us</span> <img src="https://webapi.entab.info/api/image/TPSP/public/Images/arrow.png" /> </div>
                    </div>
                </div>
            </OwlCarousel> 
        </>
    )
}
export default ContactBranch