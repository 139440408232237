import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const About = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="About Us" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>ABOUT</span> US</h3>
                                    </div>
                                </div>
                                </div>
                            <div className="col-lg-12">
                            <p>Tagore Public School was founded in the year 2012. Situated in the heart of sector-2 Palwal, the school has beautiful infrastructure with lush green lawns. The school is progressing leaps and bounds as the students strength is touching 3000 under the dynamic and progressive leadership of Academic Director Madam Ms. Manorma Arora. She is the proud recipient of Rajat Jyanti and State Award for her valuable and outstanding contribution in the field of education, Art and Culture. The school is a promising educational centre reflecting Madam Manorma’s vision to produce IITians, IAS, Doctors, Engineers, CA’s, and other successful professionals in diverse fields.  </p>

                            <p>Affiliated to CBSE (Central Board of Secondary Education) the school is following a rich curriculum based on international teaching standards with modification, which is being continually updated with activity based teaching at it’s core. We follow a child centered approach in teaching learning process while delivering the content. The school provides excellent sports facilities to nurture the talented students to hone their skills. Trained coaches are available for Athletics, Yoga, Table-Tennis, Lawn Tennis, Volleyball, Basket ball and indoor games chess, carrom, taekwondo, badminton etc. </p>
                        
                            <p>The school follows the club system to motivate each and every student to explore and polish their inner talents through various clubs – Astronomy club , Environment club, Dramatics club, Cookery, Calligraphy , Art & Craft, Music & Dance, Maths club, Science club & Computer club etc. The special Assemblies are conducted by each class to teach moral values , to keep them aware of the current socio economic problems, health related issues , celebration of festivals and important days. The school administration is helped by a strong team of student council headed by Head boy and Head girl. The school has House System from classes 3rd to   12th . The students in each class are divided in to four houses namely – Galileo, Darwin, Aristotle and Faraday. To develop the Sprit of competition and allow children to have first hand experience of planning and execution of events. House wise activities and competitions are conducted on various themes and topics. </p>
                         
                            <p>This website can not take place of visiting our school. Our stack holders are always welcome to visit our school. </p>
                            </div>
                            <div className="col-lg-12">
                                <div className="about-img">
                                
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/aboutus.jpg" className="img-fluid"/> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default About;