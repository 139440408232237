import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Facilities = () => {
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 3,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            }
        }
    };
    // const [data, setData] = useState([]);
    // useEffect(() => {
    //   const getData = async () => {
    //     const datas = await getEvents();
    //     setData(datas);
    //   };
    //   getData();
    // }, []);


    return (
        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
            {/* {data?.length > 0 &&
        data?.map((item, index) => ( */}
            <div className="item">
                <div className='facil-blk'>
                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/class-room.gif"></img>
                    <h3>Class <br></br> Rooms</h3>
                    <p>Tagore public school is a beautiful and well equipped institution set in the heart of the prestigious sector-2 in Palwal. Built to extremely high standards, the school features spacious and airy class rooms conducive to learning through advance teaching techniques that are supported by comfortable furniture.</p>
                </div>
            </div>
            <div className="item">
                <div className='facil-blk'>
                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/books.gif"></img>
                    <h3>Library<br></br> Room</h3>
                    <p>Large and pleasantly furnished extensive library supports an impressive array of fiction, nonfiction books and resources including maps, periodicals journals, news papers and subject books.  A well resourced library enables students to enrich and expand their horizons. The TPS library catalogue has a vast and diverse collection of books for children of all levels. All students have regular access to library.</p>
                </div>
            </div>
            <div className="item">
                <div className='facil-blk'>
                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/science.gif"></img>
                    <h3>Science <br></br>Lab</h3>
                    <p>The teaching emphasis for science is firmly placed on experimentation and investigation. For this the school has fully equipped science labs (Physics, Chemistry, Biology).</p>
                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/computer.gif"></img>
                                    <h3>Computer  <br></br>Lab</h3>
                                    <p>School has a fully equipped computer lab to support E-learning. The practical classes are held in the lab under the guidance of computer teacher. The software is regularly updated to ensure the students are well conversant to use the latest technology.</p>
                                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                <img src="https://webapi.entab.info/api/image/TPSP/public/Images/language.gif"></img>
                <h3>Atl <br></br>Lab</h3>
                <p>State of the art Language lab to enhance student’s learning with correct pronunciation is a centre of attraction for all students as they are exposed to a variety of creative activities. Students look forward to visiting the lab.</p>
            </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/bus.gif"></img>
                                    <h3> Transport <br></br></h3>
                                    <p>The school has large fleet of  buses fitted with CCTV cameras to ensure student’s safety and security. The school provides a safe transport for students, with a communication system and supervised pick-up and dispersal. The school buses are well - maintained and serviced regularly.</p>
                                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/smartClass.gif"></img>
                                    <h3> Smart Class  <br></br>Room</h3>
                                    <p>The school aims at enhancing the quality of  its teaching and learning. Hence it has planned to introduce smart classes right from the first year. This initiative will supplement and complement classroom instructions through application of modern technology. The concept of smart class has drawn the interest of tech-savvy  generation and Tagore Public School couldn’t have been left behind. </p>
                                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/classroom.gif"></img>
                                    <h3> Multi Purpose   <br></br>Hall</h3>
                                    <p>The extensive air-conditioned multipurpose hall is well equipped and converted to auditorium to conduct special assemblies with light and sound show. It is also used as multipurpose sports class room to teach indoor sports like carom, chess, badminton, karate, taekwondo, yoga.</p>
                                </div>
            </div>
            <div className="item">
                <div className='facil-blk'>
                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/audio.gif"></img>
                    <h3> Music &    <br></br>Dance</h3>
                    <p>The school has well equipped music room where students practice singing and playing musical instruments. The school has  a wide range of wind, string, and repercussion instruments, keyboards and drums.</p>
                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/pool-ladder.gif"></img>
                                    <h3> Splash   <br></br>Pool</h3>
                                    <p>A beautiful splash pool for Kinder-Garten is constituted to give little ones joy of water games. 
</p>
                                </div>
            </div>
            <div className="item">
            <div className='facil-blk'>
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/teaching.gif"></img>
                                    <h3> In House    <br></br>Coaching</h3>
                                    <p>Our Teaching Staff is experienced and well qualified and selected for their abilities to effectively train students on their way to professional colleges. Our teachers motivate their students to work to the best of their abilities. Our on-going programme of professional development ensures that our teachers are up-to-date with the latest teaching methodologies and developments in the field of education. Many of our teachers are involved in projects and development work that is at the forefront of E-learning and other contemporary areas of education. School has well developed programme to coach students in IIT –JEE , NTSE , Aryabhatta Classes and Olympiads to explore their abilities beyond classrooms and be in-step with the competitive world.</p>
                                </div>
            </div>

            {/* ))} */}
        </OwlCarousel>
    );
};

export default Facilities;
