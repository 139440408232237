import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Vision = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Vision Statement" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Vision </span> Statement</h3>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="about-img">
                                
                                    <img src="https://webapi.entab.info/api/image/TPSP/public/Images/vision.jpg" className="img-fluid"/> 
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p><b>At Tagore Public School, Faridabad</b>  has been established with a vision to be a world class learning centre for imparting relevant, joyful, value based high quality education to children to attain complete realization of inherent natural talents. Acquisition of skills and values which empower our students to face modern age challenges and make them dignified self-reliant and refined human beings are at the very base of all our educational strategies.</p>
                                </div>
                            
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Vision;