import React, { useState } from 'react'; 
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer' 
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
import Breadcrumb from '../components/Breadcrumb'
const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "", 
        phone: "", 
        message: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        
        if (!formData.phone) newErrors.phone = "Phone number is required."; 
        if (!formData.message) newErrors.message = "Message is required.";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'Admission'; 
        console.log('Submitting form with type:', type); // Debugging line
        try {
            const { header } = getHeader();
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            setFormData({
                name: "", 
                phone: "", 
                message: "",
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <Header />
            {/* <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us </li>
            </ul>
       </div> */}
            <Breadcrumb mid="Contact" breadcrumbName="Contact Us" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Contact </span> Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="contact-container">
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/location1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Address</h6>
                                        <p><Link to="https://maps.app.goo.gl/gFjyEVn4H8Jc8tYr9" target="_blank"> 
                                            Site No 1, HUDA Sector-2, Palwal, Haryana 121102</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/phone1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Phones</h6>
                                        <p><Link to="tel:+91-7027770212">  +91 7027770212</Link>  </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/mail1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Email</h6>
                                        <p><Link to="mailto:tagore_academy@rediffmail.com ">tagorepalwal@gmail.com </Link> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.80450339509!2d77.31509260000001!3d28.152438999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cd3c0d261a4a7%3A0xfbcfeab3b9e25f7e!2sTagore%20Public%20School!5e0!3m2!1sen!2sin!4v1728456472873!5m2!1sen!2sin" width="100%" height="350" style={{ border: " 0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
     {/* 
                    <div className='row mt-5'>
                        <div className='col-lg-12 contact-form'>
                        <form onSubmit={handleSubmit}>
                        <div className="formdiv">
                            <div className="form-group"> 
                                <input type="text" name="name" className="form-control"  placeholder="Full Name.."  value={formData.name} onChange={handleChange}/>
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div> 
                            <div className="form-group">
                                 
                                <input type="text" name="phone" className="form-control" value={formData.phone} placeholder="Contact Number.."  onChange={handleChange}/>
                                {errors.phone && <div className="error">{errors.phone}</div>}
                            </div> 
                            <div className="form-group textarea">
                                 
                                <textarea name="message" className="form-control" value={formData.message}  placeholder="Suggestion" onChange={handleChange}></textarea>
                                {errors.message && <div className="error">{errors.message}</div>}
                            </div>
                            <div className="form-group">  <input type="submit" value="Submit"/></div>
                        </div>
                       
                        <div className="row"> 
                            <div className="col-lg-12 col-md-12">
                                <div className='primary-alert'></div>
                            </div>
                        </div>
                    </form>
                        </div>
                    </div>   */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs
