import React from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        nav: true,
        dots: true, smartSpeed: 1500, // transition speed
        fluidSpeed: 2000, 
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (
        <OwlCarousel className='owl-theme main-slider' loop margin={10} {...settings}>
          
           
            {/* <div className="item">
              <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld2.jpg" />
              <div class="slide-content">
                <h2>
                Tagore Public School <br/>opening soon -<br /> <span>learn and grow </span> with us! <span></span> 
                </h2>
              </div>
            </div> */}
            <div className="item">
              <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld1.jpg" />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>  
            <div className="item">
              <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld2.jpg" />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>  
            <div className="item">
              <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld3.jpg" />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>  
            <div className="item">
            <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld4.jpg" />
            <div class="slide-content">
              <h2>
              creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
              </h2>
            </div>
          </div> 
          <div className="item">
          <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld5.jpg" />
          <div class="slide-content">
            <h2>
            creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
            </h2>
          </div>
        </div> 
        <div className="item">
        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld6.jpg" />
        <div class="slide-content">
          <h2>
          creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
          </h2>
        </div>
      </div> 
      <div className="item">
      <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld7.jpg" />
      <div class="slide-content">
        <h2>
        creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
        </h2>
      </div>
    </div> 
            <div className="item">
            <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld8.jpg" />
            <div class="slide-content">
              <h2>
              creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
              </h2>
            </div>
          </div> 
          <div className="item">
          <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld9.jpg" />
          <div class="slide-content">
            <h2>
            creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
            </h2>
          </div>
        </div> 
        <div className="item">
        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld10.jpg" />
        <div class="slide-content">
          <h2>
          creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
          </h2>
        </div>
        </div> 
        <div className="item">
        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld11.jpg" />
        <div class="slide-content">
          <h2>
          creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
          </h2>
        </div>
        </div> 
        <div className="item">
        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld12.jpg" />
        <div class="slide-content">
          <h2>
          creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
          </h2>
        </div>
        </div> 
        <div className="item">
        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/sld13.jpg" />
        <div class="slide-content">
          <h2>
          creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
          </h2>
        </div>
        </div> 
        </OwlCarousel>
    );
};

export default Events;