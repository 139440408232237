import React, { useState, useEffect } from 'react'; 
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb"; 
import {getPrincipalMessage} from'../Service/Api'
const DirectorMessage = () => { 
    useEffect(() => {
        document.title = 'Directo rMessage - Tagore Public School, Palwal ';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
     <Breadcrumb mid="About" breadcrumbName="Director Message" />
         <div className="innerSection">
             <div className="container">
                 <div className="row">
                     <div className="col-lg-12">
                     <div className="title">
                             <div>
                                 <h3><span>Director</span> Message</h3>
                             </div>
                         </div>
                         </div>
                      </div>
                <div className="row">
                    <div className="col-lg-12">
                    {data?.length > 0 ? data?.filter((div) => div.category === "Director").map((item) => ( 
                        <div className="clearfix">
                         <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="" className="col-md-4 float-md-end  left-img img-fluid"/>
                         
                         <p>  <div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                         <div className="mesdeskimg"> 
                         <h3> {item.name}</h3>
                         <h5> {item.category}</h5>
                      </div>
                         </div>
                     )) : emptyData.map((item) => (
                         <div className="clearfix"> 
                         <img src="https://webapi.entab.info/api/image/TPSP/public/Images/director.jpg" className="col-md-4 float-md-end  left-img img-fluid"/>
                        
                         <p>Education is the courage and strength to walk on the path of truth, humility and hard work.  Education is the soul of the society as it passes from one generation to the other.
                         Tagore public school is a wonderful place for students of diverse back grounds to grow educationally, get involved and have a meaningful educational experience. We strive to create an environment where each and every student is given the attention, value and respect necessary to achieve his/her individual success. Tagore public school aspires to empower all students to succeed in their lives as we prepare them for life beyond school. Our commitment is to provide learning opportunities in a safe and rigorous Academic oriented environment. We inspire our students to meet the challenges of the next level of their educational experience. </p>
                        
                        <p>Being the leading provider of senior secondary education in the vicinity of Palwal , our aim is to provide the very best possible education to the students who are part of Tagore family. Our school is a learning community which encourages staff, students, and parents to involve themselves fully in all aspects of school life and to identify themselves with its core values of respect, responsibility, integrity, cooperation and achievement.</p>
                        
                        <p>We expect high standards in all that we do or undertake. We encourage our students to aim high in their studies, to work hard and to be sensitive to the rights and needs of others. All of this is made possible by the strong partnership that is developed with our parents to create a positive learning environment.</p>
                           <h4>An investment in knowledge pays the best dividends.</h4>
                           <div className="mesdeskimg"> <h3> Name</h3> <h5>  Director  </h5></div>
                         </div> 
                         ))}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Copyright />
     </>
  )
}

export default DirectorMessage

