import React from "react";
import { Link } from 'react-router-dom';
import ContactBranch from '../components/ContactBranch'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-xl-2 col-lg-3">
                                <img src="https://webapi.entab.info/api/image/TPSP/public/Images/ftr-logo.png" />
                            </div>

                            <div className="col-xl-3 col-lg-9">
                                <div className="quick-link">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/about'>About Us</Link></li>
                                        <li><Link to=""> School Management</Link></li>
                                        <li><Link to=""> Admissions</Link></li>
                                        <li><Link to="/ErpSoftware"> Erp Software</Link></li>
                                        <li><Link to="/Pphelp"> Parent Portal</Link></li>
                                        <li><Link to="/VideoGallery"> Video Gallery</Link></li>
                                        <li><Link to="/Career"> Careers</Link></li>
                                        <li><Link to=""> Student Login</Link></li>
                                        <li><Link to="/Cbse"> CBSE Mandatory</Link></li>
                                        {/* <li><Link to=""> Parent Login</Link></li> 
                                      <li><Link to="/Pphelp"> Parent portal </Link></li> 
                                     <li><Link to="/Gallery"> Picture Gallery</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2  col-lg-6">
                                <div className="cont-dtl">
                                    <h3>Location</h3>
                                    <p><i class="bi bi-geo-alt"></i> <span><Link to="https://maps.app.goo.gl/gFjyEVn4H8Jc8tYr9">Site No 1, HUDA Sector-2, Palwal, Haryana 121102</Link></span></p>
                                    <p><i class="bi bi-telephone-fill"></i>   <span class="responsive-number"><Link to="tel: +91-8930729377"> +91 8930729377</Link>,<Link to="tel: +91-7027770212"> +91 7027770212</Link> </span></p>
                                    <p><i class="bi bi-globe"></i><span> <Link to="www.tps89.in" target="_blank">www.tagore.edu.in</Link></span></p>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6">
                                <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.80450339509!2d77.31509260000001!3d28.152438999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cd3c0d261a4a7%3A0xfbcfeab3b9e25f7e!2sTagore%20Public%20School!5e0!3m2!1sen!2sin!4v1728454612317!5m2!1sen!2sin"></iframe>
                            </div>

                            <div className="col-xl-3 col-lg-6">
                                <div className="branch">
                                    <ContactBranch />
                                </div>
                            </div>



                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;