import React, { useEffect, useState } from "react"; 
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";  
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
const Assemblies = () => { 
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
      useEffect(() => {
      const fetchData = async () => {
           try{
             const galleryData = await getGalleryInner();
             setData(galleryData);
           }catch (error) {
             console.log("Error fetching gallery data:",error)
           }finally{
             setLoading(false)
           }
        
        };
      fetchData();
      }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
     <Breadcrumb mid="Gallery" breadcrumbName="Assemblies" />
        <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="title">
                            <div>
                                <h3><span> Assemblies</span> </h3>
                            </div>
                        </div>
                        </div>
                    </div>
           
              <div className="row">
              {data.length > 0 ? (data?.filter((item) => item.school === "Senior"  &&  item.category === "Assemblies").map((item, index) => { 
                   
                  return (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" key={index}>
                      <div className="gallery-inner">
                        <div className="gallery-blks">
                          <div className="backDiv"></div>
                          <img src={`https://webapi.entab.info/api/image?url=${item?.attachments[0]}`} className="img-fluid" alt=" " />
                        </div>
                        <div className="gallery-content">
                          <div className="gallery-date">
                            
                            <div className="gall-num">
                              <h5><span>Number Of Photos :</span>{item?.attachments.length}</h5>
                            </div>
                          </div>
                          <div className="gallery-title">
                            <h3>{item.title}</h3>
                          </div>
                          <a onClick={() => navigate(`/SubGallery?id=${item._id}`)} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                emptyData.map((data, index) => (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount">
                    <div className="gallery-inner">
                      <div className="gallery-blks">
                        <div className="backDiv"></div>
                        <img src="https://webapi.entab.info/api/image/TPSP/public/Images/gal-1.jpg" className="img-fluid" alt=" " />
                      </div>
                      <div className="gallery-content">
                        <div className="gallery-date">
                          <h6>DD<span> Mon </span> </h6>
                          <div className="gall-num">
                            <h5><span>Number Of Photos :</span>NO</h5>
                          </div>
                        </div>
                        <div className="gallery-title">
                          <h3>title</h3>
                        </div>
                        <Link to="/SubGallery" className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div> 
</div>
</div>
<Footer />
<Copyright />
    </>
  )
}

export default Assemblies
