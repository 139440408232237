import React, { useEffect, useState } from 'react'; 
import { getEvents } from '../Service/Api';
import Slider from "react-slick";
const Events = () => {
    var settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2, 
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1, 
              }
            }
          ]
      };
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
     useEffect(() => {
         const fetchData = async () =>{
                try{
                   const eventData = await getEvents();
                   setData(eventData) ;  
                }catch(error){
                 setError(error);
                }finally{
                 setLoading(false)
                }
         }
         fetchData();
     },[]);
  
     const emptyArray = [
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"}, 
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
        { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"}
  
     ] 
    return (
        <Slider {...settings}>
        {data && data.length > 0 ? (data.map((item,index) => {  
            const date = new Date(item.date);
            const mon = monthNames[date.getMonth()];
            const eventdate = date.getDate();
            const yy = date.getFullYear();
                 console.log(yy);
  
             return (
               <div className="item" key={index}>
                   <div className='evt-blk'>
                      <div className="evt-img">
                          {item.images.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid"   alt="TPSP"/>):(<img src="https://webapi.entab.info/api/image/TPSP/public/Images/events.jpg" className="img-fluid"   alt="TPSP"/>)}
                          <p className='date'>
                              {mon} <span>{eventdate}</span>  {yy}
                          </p>
                          <div className='readmore'>READ MORE  <img src="https://webapi.entab.info/api/image/TPSP/public/Images/arrow.png" /></div>
                          <div className="evt-title"><h3>{item.title}</h3></div> 
                          <p>{item.description}</p>
                      </div>
                      <div className='evt-content'>                        
                          <p>{item.venue} </p>
                      </div>
                  </div>
                </div>
   
                      )
                    })) : (emptyArray.map((item,index) => (
          <div className="item" key={index}>
          <div className='evt-blk'>
                      <div className="evt-img">
                          <img src="https://webapi.entab.info/api/image/TPSP/public/Images/events.jpg" />
                          <p className='date'>
                               MM <span> DD</span> 2024
                          </p>
                          <div className='readmore'>READ MORE  <img src="https://webapi.entab.info/api/image/TPSP/public/Images/arrow.png" /></div>
                          <div className="evt-title"><h3>{item.title}</h3></div> 
                          <p>{item.description}</p>
                      </div>
                      <div className='evt-content'>                        
                          <p>{item.venue} </p>
                      </div>
                  </div>
    </div> 
                      )))}  
                      </Slider>
    );
};

export default Events;
