import React, { useState, useEffect } from 'react';
import { getGallery } from '../Service/Api';
import { Link } from 'react-router-dom';

const Gallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const categories = [
    { name: "Activities", path: "/Activites", placeholder: "Activites4.jpg" },
    { name: "Competitions", path: "/Competition", placeholder: "Competition_8.jpg" },
    { name: "Assemblies", path: "/Assemblies", placeholder: "Assembly6.jpg" },
    { name: "Achievements", path: "/Achivement", placeholder: "sld7.jpg" },
    { name: "Celebrations", path: "/Celebrations", placeholder: "Competition9.jpg" },
    { name: "Sports", path: "/Sports", placeholder: "Sports13.jpg" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderCategory = (category) => {
    const categoryData = data.find(item => item.school === "Senior" && item.category === category.name);

    return (
      <div className="col-lg-4 col-md-4 col-sm-6" key={category.name}>
        <div className="gal-img">
          <img
            src={
              categoryData
                ? `https://webapi.entab.info/api/image/${categoryData.attachments[0]}`
                : `https://webapi.entab.info/api/image/TPSP/public/Images/${category.placeholder}`
            }
            className="img-fluid"
          />
          <div className="description">
            <Link to={category.path}>
              <h3>{category.name}</h3>
              <span>
                <img src="https://webapi.entab.info/api/image/TPSP/public/Images/arrow.png" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row gallery-box">
      { 
        categories.map(renderCategory)
      }
    </div>
  );
};

export default Gallery;
