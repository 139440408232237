import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Infrastructure = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="Infrastructure" breadcrumbName="Infrastructure" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3> Infrastructure </h3>
                                    </div>
                                </div>
                                </div> 
                        </div>
                        <div className="row inner_cont">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 infra_sec">
                        <div className="verticaltabs">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                          <button className="nav-link active" id="v-pills-bio-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bio" type="button" role="tab" aria-controls="v-pills-bio" aria-selected="true">Biology Lab</button>
                          <button className="nav-link" id="v-pills-che-tab" data-bs-toggle="pill" data-bs-target="#v-pills-che" type="button" role="tab" aria-controls="v-pills-che" aria-selected="false">Chemistry Lab</button>
                          <button className="nav-link" id="v-pills-phy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-phy" type="button" role="tab" aria-controls="v-pills-phy" aria-selected="false">Physics Lab</button>
                          <button className="nav-link" id="v-pills-Library-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Library" type="button" role="tab" aria-controls="v-pills-Library" aria-selected="false">Library</button>
                          <button className="nav-link" id="v-pills-computer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-computer" type="button" role="tab" aria-controls="v-pills-computer" aria-selected="false">Computer Lab</button>
                          <button className="nav-link" id="v-pills-Science-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Science" type="button" role="tab" aria-controls="v-pills-Science" aria-selected="false">Science Lab</button>
                          <button className="nav-link" id="v-pills-Sports-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Sports" type="button" role="tab" aria-controls="v-pills-Sports" aria-selected="false">Sports</button>
                          <button className="nav-link" id="v-pills-Transport-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Transport" type="button" role="tab" aria-controls="v-pills-Transport" aria-selected="false">Transport</button> 
                      
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" id="v-pills-bio" role="tabpanel" aria-labelledby="v-pills-bio-tab"><h3>Biology Lab</h3>
                              <div className="row">
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/biology_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/biology_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div> 
                           
                             </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-che" role="tabpanel" aria-labelledby="v-pills-che-tab"><h3>Chemistry Lab</h3>
                                <div className="row">
                             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/chemistry_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/chemistry_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                             </div> 
                             </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-phy" role="tabpanel" aria-labelledby="v-pills-phy-tab"><h3>Physics Lab</h3>
                      <div className="row">
                             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/physics_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/physics_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                             </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/physics_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/physics_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-Library" role="tabpanel" aria-labelledby="v-pills-Library-tab"><h3>Library</h3>
                            <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/library_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/library_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                            </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/library_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/library_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div>
                      
                          </div>
                          <div className="tab-pane fade" id="v-pills-computer" role="tabpanel" aria-labelledby="v-pills-computer-tab"><h3>Computer Lab</h3>
                      
                         <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/computer_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/computer_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                            </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/computer_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/computer_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/computer_3.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/computer_3.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-Science" role="tabpanel" aria-labelledby="v-pills-Science-tab"><h3>Science Lab</h3>
                      
                             <div className="row">
                             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Science_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Science_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                             </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Science_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Science_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Science_3.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Science_3.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Science_4.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Science_4.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-Sports" role="tabpanel" aria-labelledby="v-pills-Sports-tab"><h3>Sports</h3>
                          <div className="row">
                             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                             </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports3.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports3.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports4.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports4.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports5.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports5.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports6.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports6.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div> 
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports9.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports9.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports10.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports10.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports11.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports11.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports12.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports12.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports13.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports13.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports14.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports14.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports15.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports15.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports16.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports16.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports17.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports17.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Sports18.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Sports18.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div>
                      
                          </div>
                          <div className="tab-pane fade" id="v-pills-Transport" role="tabpanel" aria-labelledby="v-pills-Transport-tab"><h3>Transport</h3>
                       <div className="row">
                             <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Transport_1.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Transport_1.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                             </div>
                           <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/TPSP/public/Images/Transport_2.jpg"><img src="https://webapi.entab.info/api/image/TPSP/public/Images/Transport_2.jpg" alt="Tagore Public School, Palwal" className="img-fluid"/></a>
                           </div>
                             </div> 
                          </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Infrastructure;